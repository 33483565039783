<template>
    <div class="box">
      <div class="pro">
        <div class="list" :class="proIndex==index?'active':''" 
        v-for="(item,index) in proList" :key="index"
        @click="proTab(item,index)">
        <span class="name" v-if="!item.children">{{item.cateName}}</span>
        <el-popover  v-if="item.children"
          placement="bottom"
          width="400"
          trigger="click">
          <div class="pro-ch" >
            <div class="pro-ch-list" :class="proIndexs==index?'active':''" 
        v-for="(item2,index) in item.children" :key="index"
        @click="proTabs(item2,index)">{{item2.cateName}}</div>
          </div>
          <span slot="reference" class="name">{{item.cateName}}</span>
        </el-popover>
        </div>
      </div>

      <div id="kinds" class="adv">
        <!-- <img src="@/assets/img/products.png" alt=""> -->
        <Carousel type="Products"></Carousel>
      </div>

      <div id="productList" class="cont-box">
        <div class="container">
          <ProductList ref="ProductList" type="products"></ProductList>
        </div>
      </div>
    </div>
</template>
<script>
import { getAction } from "@/api/manage";
export default {
  name: "index",
 
  data() {
    return {
      proList:[
        // {name:'分类产品',type: 1},
        // {name:'分类产品',type: 2},
        // {name:'分类产品',type: 3},
        // {name:'分类产品',type: 4},
        // {name:'分类产品',type: 5},
        // {name:'分类产品',type: 6},
      ],
      proIndex: -1,
      // proLists: [
      //   {name:'产品',type: 1},
      //   {name:'产品',type: 2},
      //   {name:'产品',type: 3},
      //   {name:'产品',type: 4},
      //   {name:'产品',type: 5},
      //   {name:'产品',type: 6},
      // ],
      proIndexs: 0,
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
    }
  },
  mounted() {
    this.listDataTree()

    this.search = this.$route.query
    if (this.search.search) {
      this.searchFuc()
    }
  },
  methods: {
    searchFuc() {
      this.$refs.ProductList.shopSearch(this.search.search)
      document.getElementById('productList').scrollIntoView({ behavior: 'smooth' })
    },
    listDataTree() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tstoreCategory/listDataTree",
        {
          '__ajax':"json",
          '__login':false,
          // 'language': localStorage.getItem('lang'),
          bigType: 1,
        }
      ).then((res) => {
        // console.log(res.data);
        this.proList = res.data
      });
    },
    plush() {
      this.$router.push({
        path: "/ProductDeta",
        // query: { item: this.search },
      });
    },
    proTab(item,index) {
      console.log(item,index);
      this.proIndex = index
      this.proIndexs = 0
      this.$refs.ProductList.types = item.id
      this.$refs.ProductList.shop()
    },
    proTabs(item,index) {
      // console.log(item.type);
      this.proIndexs = index
      this.$refs.ProductList.types = item.id
      this.$refs.ProductList.shop()
      document.getElementById('productList').scrollIntoView({ behavior: 'smooth' })
    },
    // 分页方法
    handleSizeChange(val) {
      this.size = val
      // this.loadData()
    },
    // 分页方法
    handleCurrentChange(val) {
      this.current = val
      // this.loadData()
    },
  }
}
</script>

<style lang="scss" scoped>
.pro{
  display: flex;
  justify-content: center;
  .list{
   
    text-align: center;
    line-height: 68px;
    color: #707070;
    cursor: pointer;
    font-size: 18px;
    background: #F3F3F3;
    margin-right: 5px;
    transition: all 0.5s;
  }
  .name{
    display: inline-block;
     width: 210px;
    height: 68px;
  }
  
  .active{
    background: #BCAA94;
    color: #fff;
  }
}
.pro-ch{
  display: flex;
  .pro-ch-list{
    width: 124px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #707070;
    cursor: pointer;
    font-size: 14px;
    background: #F3F3F3;
    margin-right: 5px;
    transition: all 0.5s;
    border-radius: 4px;
  }
  .active{
    background: #BCAA94;
    color: #fff;
  }
}
.adv{
  margin-top: 30px;
  img{
    width: 100%;
    height: 400px;
  }
}


.cont-box {
    background: url("~@/assets/img/bg.png");
    background-size: 100% 100%;
    padding: 80px 0;
    // min-height: 800px;
    .container{
      width: 1300px;
      margin: 0 auto;
    }
  }


@media (max-width: 768px){
  .pro{
    overflow: auto;
  }
  .pro .list{
    line-height: 35px;
    font-size: 16px;
  }
  .pro .name{
    width: 90px;
    height: 35px;
  }
  .box{
    padding: 0;
  }
  .cont-box{
    padding: 15px 0;
  }
}
</style>
<style scoped>
.box >>> .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #CDBA93;
}
.box >>> .el-select-dropdown__item.selected,.box >>> .el-select-dropdown__item.selected{
  color: #CDBA93 !important;
}
@media (max-width: 768px){
  .box >>> .el-pagination__jump{
    display: none;
  }
}
</style>